<template>
  <v-snackbar
    v-model="show"
    :color="color"
    multi-line
    :attach="disableTeleportation"
  >
    {{ $t(text) }}
    <a v-if="link" :href="link" target="_blank" @click="show = false">{{
      $t('Download')
    }}</a>
    <a v-if="url" :href="url" target="_blank" @click="show = false">{{
      $t('Open')
    }}</a>
    <template v-slot:actions>
      <v-btn variant="text" dark @click="show = false">
        {{ $t('Close') }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
const disableTeleportation = ref(false);

const show = ref(false);
const text = ref('');
const color = ref('info');
const link = ref(null);
const url = ref(null);

function displaySnackbar({
  text: snackbarText,
  color: snackbarColor = 'info',
  link: snackbarLink,
  url: snackbarUrl,
}) {
  text.value = snackbarText;
  color.value = snackbarColor;
  show.value = true;
  link.value = snackbarLink || null;
  url.value = snackbarUrl || null;
}

onMounted(() => {
  const handler = (event) => displaySnackbar(event.detail);
  window.addEventListener('snackbar', handler);
  onBeforeUnmount(() => {
    window.removeEventListener('snackbar', handler);
  });
});
defineExpose({
  disableTeleportation,
});
</script>

<style scoped>
a {
  text-decoration: none;
  padding: 6px;
  color: #fff !important;
}
</style>
