import axios, { InternalAxiosRequestConfig } from 'axios';
import { useAuthStore } from '@/store';
import { i18n } from '../plugins/i18n';

export const API_ORIGIN = localStorage.getItem('VUE_APP_PAYOUT_SERVER_ORIGIN');
export const BASE_AUTHENTICATED_API_URL = API_ORIGIN + '/api/v1';
export const BASE_PUBLIC_API_URL = API_ORIGIN + '/pbl/api/v1';

export const CORE_API_ORIGIN = localStorage.getItem(
  'VUE_APP_CORE_SERVER_ORIGIN',
);
export const BASE_CORE_API_URL = CORE_API_ORIGIN + '/api/v1';

const MERCHANT_ID = localStorage.getItem('MERCHANT_ID');
// Authorization header is inserted automatically to each request (if token is present)
export const authInterceptor = [
  (config: InternalAxiosRequestConfig) => {
    const authStore = useAuthStore();
    if (authStore.getToken) {
      config.headers.Authorization = `Bearer ${authStore.getToken}`;
    }
    return config;
  },
  (error: unknown) => Promise.reject(error),
];

export const languageInterceptor = [
  (config: InternalAxiosRequestConfig) => {
    config.headers['Accept-language'] = i18n.global.locale.value;
    return config;
  },
  (error: unknown) => Promise.reject(error),
];

export const merchantInterceptor = [
  (config: InternalAxiosRequestConfig) => {
    config.headers['x-merchant'] = MERCHANT_ID;
    return config;
  },
  (error: unknown) => Promise.reject(error),
];

export const timezoneInterceptor = [
  (config: InternalAxiosRequestConfig) => {
    if (
      localStorage.getItem('userTimeZone') &&
      localStorage.getItem('userTimeZone') !== 'null'
    ) {
      config.headers['X-Timezone'] = localStorage.getItem('userTimeZone');
    }
    return config;
  },
  (error: unknown) => Promise.reject(error),
];

export const AuthEPsService = (url: string) =>
  axios.create({
    baseURL: API_ORIGIN + url,
  });

export const AuthCoreEpsService = (url: string) =>
  axios.create({
    baseURL: BASE_CORE_API_URL + url,
  });

export const AuthorizedService = (url: string) => {
  const authorizedService = AuthEPsService(url);
  authorizedService.interceptors.request.use(...authInterceptor);
  authorizedService.interceptors.request.use(...languageInterceptor);
  authorizedService.interceptors.request.use(...merchantInterceptor);
  authorizedService.interceptors.request.use(...timezoneInterceptor);
  return authorizedService;
};

export const AuthorizedCoreService = (url: string) => {
  const authorizedService = AuthCoreEpsService(url);
  authorizedService.interceptors.request.use(...authInterceptor);
  authorizedService.interceptors.request.use(...languageInterceptor);
  authorizedService.interceptors.request.use(...timezoneInterceptor);
  return authorizedService;
};
