import { createI18n } from 'vue-i18n';

export const i18n = createI18n({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  silentTranslationWarn: true,
  missing: (locale, key) => {
    // Only show warnings for the 'ar' locale
    if (locale === 'ar') {
      console.warn(`[intlify] Missing key '${key}' in '${locale}' locale`);
    }
  },
});
