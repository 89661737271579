import { AuthorizedService, AuthorizedCoreService } from './index';

const navigationService = AuthorizedService(`/v1/ui/dashboard`);
const timezoneService = AuthorizedCoreService('');

class NavigationService {
  static getNavigation() {
    return navigationService({
      method: 'GET',
      url: '/menu/',
    });
  }
  static setTimeZone(data: { timezone: string }) {
    return timezoneService({
      method: 'PUT',
      url: '/user/update-timezone/',
      data,
    });
  }
}

export default NavigationService;
