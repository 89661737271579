<template>
  <div v-if="mdAndDown">
    <v-navigation-drawer v-model="model" width="300">
      <v-divider></v-divider>
      <v-list>
        <template v-for="(item, key) in items" :key="key">
          <v-list-group
            v-if="item.children && item.children.length"
            :prepend-icon="`mdi-${item.icon}`"
            :value="item.verbose_name"
            class="border-b-sm"
          >
            <template v-slot:activator="{ props }">
              <v-list-item
                :title="item.verbose_name"
                v-bind="props"
                :class="{
                  active: isActive(item),
                }"
              ></v-list-item>
            </template>
            <v-list-item
              v-for="(child, childKey) in item.children"
              :key="childKey"
              @click="navigateToUrl(localePath(child.route_url))"
              :class="{
                active: isActive(child),
              }"
            >
              <v-list-item-title>
                <div>
                  <v-icon left class="mr-3">mdi-{{ child.icon }} </v-icon>
                  {{ child.verbose_name }}
                </div>
                <v-badge
                  v-if="child.badge"
                  inline
                  :content="child.badge"
                  size="x-small"
                  rounded="md"
                />
              </v-list-item-title>
            </v-list-item>
          </v-list-group>
          <v-list-item
            v-else
            class="border-b-sm"
            :class="{
              active: isActive(item),
            }"
            @click="navigateToUrl(localePath(item.route_url))"
          >
            <v-list-item-title>
              <div>
                <v-icon left class="mr-3">mdi-{{ item.icon }} </v-icon>
                {{ item.verbose_name }}
              </div>
              <v-badge
                v-if="item.badge"
                inline
                :content="item.badge"
                size="x-small"
                rounded="md"
              />
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script setup lang="ts">
import { PropType } from 'vue';
import { localePath } from '@/composables/index';
import { NavigationItems } from '../interface/navigation';
import { isActive } from '@/composables/index';
import { navigateToUrl } from 'single-spa';
import { useDisplay } from 'vuetify';

const { mdAndDown } = useDisplay();
const model = defineModel<boolean>();

defineProps({
  items: {
    type: Array as PropType<NavigationItems[]>,
    required: true,
  },
});
</script>
<style scoped lang="scss">
.v-navigation-drawer__scrim {
  position: fixed !important;
}
.active :deep(.v-list-item-title) {
  color: #f77d1a;
}
.active {
  color: #f77d1a;
}

:deep(.v-list-item-title) {
  display: flex;
  justify-content: space-between;
  word-wrap: break-word;
  white-space: normal;
  word-break: break-word;
  text-overflow: unset;
  line-height: 24px;
  font-size: 14px;
  font-weight: 500;
  color: #8a8a8a;
}
</style>
