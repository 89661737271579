import { h, createApp } from 'vue';
import singleSpaVue from 'single-spa-vue';

import App from './App.vue';
import router from './router';

import vuetify from './plugins/vuetify';
import { i18n } from './plugins/i18n';
import Fetching from './plugins/fetching';
import Mitt from './plugins/mitt';
import { pinia } from './plugins/pinia';
import 'vuetify/styles';
import SessionStateWorker from './lib/session';
import './assets/css/main.css';

const vueLifecycles = singleSpaVue({
  createApp,
  appOptions: {
    render() {
      return h(App, {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        idToken: this.idToken,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        logout: this.logout,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        refreshToken: this.refreshToken,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        sharedMFState: this.sharedMFState,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        serverOrigin: this.serverOrigin,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        isKeycloakAuth: this.isKeycloakAuth,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        merchant: this.merchant,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        keycloakDomain: this.keycloakDomain,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        isMerchantAdmin: this.isMerchantAdmin,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        arabicTranslation: this.arabicTranslation,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        translations: this.translations,
        // // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // //@ts-ignore
        // sentryUrl: thi.sentryUrl,
        // // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // //@ts-ignore
        // sentrySampleRate: thi.sentrySampleRate,
        // // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // //@ts-ignore
        // ignoredSentryErrors: thi.ignoredSentryErrors,
        // // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // //@ts-ignore
        // checkSentryErrors: thi.checkSentryErrors,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        isLocal: this.isLocal,
      });
    },
  },
  handleInstance(app) {
    app.use(router);
    app.use(vuetify);
    app.use(i18n);
    app.use(Fetching);
    app.use(Mitt);
    app.use(pinia);

    if (window.SharedWorker) {
      app.config.globalProperties.$sessionStateWorkerInstance =
        new SessionStateWorker();
    }
  },
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
