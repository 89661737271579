<template>
  <v-dialog
    v-model="showModal"
    width="518"
    persistent
    class="session-modal"
    margin="auto"
  >
    <v-card class="text-center">
      <v-card-title
        class="oo-dialog-title justify-center headline session-modal-title"
        style="
          text-transform: capitalize;
          position: relative;
          padding: 10px !important;
        "
      >
        {{ $t('Careful...') }}
      </v-card-title>
      <div class="dialog-title justify-center" style="text-align: center">
        <p>{{ $t('The session expires in ') }} {{ countDownTimer }}</p>
      </div>
      <v-card-text
        style="
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      >
        <p class="dialog-text">
          {{
            $t(
              `If you are still using ottu, press the "Wait, I'm not done yet" button, otherwise you will be disconnected.`,
            )
          }}
        </p>
      </v-card-text>
      <v-card-actions class="justify-center dialog-buttons text-capitalize">
        <v-btn
          class="confirm-btn btn-refund"
          color="refund-btn"
          variant="text"
          :loading="loading"
          @click="refreshTokenManually"
        >
          {{ $t("Wait, I'm not done yet") }}
        </v-btn>
        <v-btn
          class="confirm-btn btn-refund"
          color="refund-btn"
          @click="logout"
        >
          {{ $t('Logout') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { isLoggedIn, parseJwt } from '../lib/auth';
import { useAuthStore, useConfigStore } from '@/store';

import { ref, watch, onMounted, computed, getCurrentInstance } from 'vue';
const sessionStateWorkerInstance =
  getCurrentInstance()?.appContext.config.globalProperties
    .$sessionStateWorkerInstance;

const processReqSilent =
  getCurrentInstance()?.appContext.config.globalProperties.$processReqSilent;
const props = defineProps(['isIdle']);

const showModal = ref(false);
const loading = ref(false);

const authStore = useAuthStore();
const configStore = useConfigStore();

const countDownTimer = computed(() => configStore.getIdleCountdownTimer);

const InitTokenRefresh = () => {
  const token = authStore.getToken;

  if (!token) return;

  let intervalTime =
    Number(localStorage.getItem('VUE_APP_SESSION_IDLE')) || 1 * 60 * 60 * 1000;

  if (!configStore.getIsKeycloakAuth) {
    const exp = parseJwt(token).exp;
    intervalTime = (exp - 60 - Date.now() / 1000) * 1000 || 1 * 60 * 60 * 1000;
  }

  const worker = sessionStateWorkerInstance;

  if (worker) {
    worker.sendMessage({
      action: 'startRefreshInterval',
      refreshIntervalTime: intervalTime,
    });

    window.addEventListener('beforeunload', () => {
      worker.sendMessage({ action: 'stopRefreshInterval' });
    });
  }
};

const startIdleWorker = () => {
  const worker = sessionStateWorkerInstance;

  if (worker) {
    worker.sendMessage({
      action: 'startIdleCountdown',
      countdown: countDownTimer.value,
    });
  }
};

const stopWorkerCountdown = () => {
  const worker = sessionStateWorkerInstance;

  if (worker) {
    worker.sendMessage({ action: 'stopIdleCountdown' });
  }
};

const refreshTokenManually = async () => {
  loading.value = true;
  stopWorkerCountdown();

  try {
    if (configStore.getIsKeycloakAuth) {
      authStore.getRefreshToken?.();
    } else {
      await processReqSilent(
        authStore.getRefreshToken?.(localStorage.getItem('refresh')),
      );
    }
  } catch (err) {
    sessionStateWorkerInstance.logoutAcrossTabs();
  }

  loading.value = false;
  resetToDefaultValue();
};

const resetToDefaultValue = () => {
  showModal.value = false;
};

const logout = () => {
  resetToDefaultValue();
  sessionStateWorkerInstance.logoutAcrossTabs();
};

const checkSession = () => {
  if (showModal.value) return;

  showModal.value = true;
  startIdleWorker();
};

watch(
  () => props.isIdle,
  async (newVal, oldVal) => {
    if (!configStore.getEnableSessionTimeout || !authStore.getToken) return;
    if (newVal !== oldVal && isLoggedIn()) {
      checkSession();
    }
  },
);

watch(countDownTimer, () => {
  if (countDownTimer.value === 60) {
    resetToDefaultValue();
  }
});

onMounted(() => {
  InitTokenRefresh();
});
</script>

<style scoped>
.session-modal {
  max-height: 364px !important;
}
.btn-refund {
  background-color: #f77c1a !important;
}
.session-modal-title {
  max-width: 100% !important;
  background-color: #f77c1a !important;
}
.v-card__title {
  color: white;
  font-size: 35px !important;
}
.dialog-title {
  font-size: 25px;
}
.btn-refund {
  font-size: 14px;
  padding: 0 12px;
}
.dialog-text {
  font-size: 16px;
}
</style>
