<template>
  <v-app id="navigation_v2">
    <Navigation />
    <SessionModal :isIdle="isIdle" />
    <Vidle
      v-if="idleTime"
      @idle="onIdle"
      @refresh="onRefresh"
      :duration="idleTime"
      :syncKey="merchantID"
    />
    <Snackbar />
  </v-app>
</template>

<script setup>
import Vidle from 'v-idle';
import Navigation from './components/Navigation.vue';
import SessionModal from './components/SessionModal.vue';
import { onMounted, watchEffect, watch, ref, computed } from 'vue';
import { logout, getToken, refreshToken } from '@/lib/auth';
import { useAuthStore, useConfigStore } from '@/store/index';
import { useRoute } from 'vue-router';
import { i18n } from '@/plugins/i18n';
import { useI18n } from 'vue-i18n';
import { useLocale } from 'vuetify';
import Snackbar from './components/Snackbar.vue';

const props = defineProps([
  'idToken',
  'logout',
  'refreshToken',
  'sharedMFState',
  'serverOrigin',
  'isKeycloakAuth',
  'merchant',
  'keycloakDomain',
  'isMerchantAdmin',
  // 'ws_notification',
  // 'noNavBar',
  // 'traceSampleRate',
  // 'foodOrderingColor',
  'arabicTranslation',
  'translations',
  'sentryUrl',
  'sentrySampleRate',
  'ignoredSentryErrors',
  'checkSentryErrors',
  'isLocal',
]);

const isIdle = ref(false);
const route = useRoute();
const i18nUse = useI18n();
const { current } = useLocale();

const authStore = useAuthStore();
const configStore = useConfigStore();

configStore.setIsKeycloakAuth(props.isKeycloakAuth);
configStore.setIsMerchantAdmin(props.isMerchantAdmin);

const setUserDetails = (user, config, sections) => {
  authStore.setUser(user);
  configStore.setConfig(config);
  const configIdletime = !config.enable_session_timeout
    ? 24 * 60 * 60
    : localStorage.getItem('VUE_APP_SESSION_IDLE') / 1000 || 1200;

  configStore.setEnableSessionTimeout(config.enable_session_timeout);
  configStore.setIdleTime(configIdletime);
  configStore.setRenewalDate(config.renewal_date);
  configStore.setSections(sections);
};

if (configStore.isKeycloakAuth) {
  authStore.setToken(props.idToken());
  authStore.setRefreshToken(props.refreshToken);
  authStore.setLogout(props.logout);
  configStore.setKeyCloakDomain(props.keycloakDomain);
  configStore.setMerchant(props.merchant);
  if (props.idToken()) {
    setUserDetails(
      props.sharedMFState.user,
      props.sharedMFState.config,
      props.sharedMFState.sections,
    );
  }
} else {
  authStore.setToken(getToken);
  authStore.setLogout(logout);
  authStore.setRefreshToken(refreshToken);
}

const idleTime = computed(() => {
  return configStore.getIdleTime;
});

const merchantID = computed(() => {
  return configStore.getMerchant || window.location.hostname;
});

const onIdle = () => {
  isIdle.value = true;
};

const onRefresh = () => {
  isIdle.value = false;
};

const checkLanguage = (path) => {
  if (path.length > 3) {
    const lang = path.slice(1, 3);
    if (i18n.global.locale.value === lang) return;
    if (lang === 'en' || lang === 'ar') {
      i18n.global.locale.value = lang;
      current.value = i18n.global.locale.value;
    }
  }
};

const setLang = () => {
  const translations = props.translations();

  i18nUse.setLocaleMessage('en', translations.en || {});
  i18nUse.setLocaleMessage(
    'ar',
    { ...props.arabicTranslation, ...translations.ar } || {},
  );
};

watchEffect(() => {
  if (route?.path) {
    checkLanguage(route.path);
  }
});

watch(
  () => props.sharedMFState.isLoadingConfig,
  (newValue) => {
    if (newValue === false) {
      setUserDetails(
        props.sharedMFState.user,
        props.sharedMFState.config,
        props.sharedMFState.sections,
      );
    }
  },
);

onMounted(() => {
  setLang();
});
</script>

<style lang="scss">
.v-layout--full-height {
  height: auto !important;
  min-height: auto !important;
}
#navigation_v2 {
  .v-application__wrap {
    z-index: 1000;
    background: white;
    position: fixed;
    top: 0;
    width: 100%;
    display: block;
    height: auto;
    min-height: auto;
  }
}
.v-idle {
  display: none;
}
</style>
