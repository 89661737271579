<template>
  <div>
    <Header :drawer="drawer" @toggleDrawer="toggle()" />
    <TopMenu :items="items" :loading="isFetchingMenu" />
    <SideMenu :items="items" v-model="drawer" />
  </div>
</template>

<script setup lang="ts">
import Header from './Header.vue';
import TopMenu from './TopMenu.vue';
import { NavigationItems } from '../interface/navigation';
import SideMenu from './SideMenu.vue';
import { ref, watch } from 'vue';
import NavigationService from '@/services/merchantNavigation';
import { useProcessReq } from '@/composables';
import { i18n } from '@/plugins/i18n';

const items = ref<NavigationItems[]>([]);
const drawer = ref<boolean>(false);
const isFetchingMenu = ref<boolean>(false);
const processReq = useProcessReq();

const toggle = () => {
  drawer.value = !drawer.value;
};

const fetchMerchantAccounts = async () => {
  try {
    const res = await processReq(
      NavigationService.getNavigation(),
      false,
      true,
    );
    items.value = res.data;
    isFetchingMenu.value = false;
    if (items.value.length) setItemsInCache('cachedNavigation', res.data);
  } catch (err) {
    isFetchingMenu.value = false;
    console.error('Failed to fetch navigation items:', err);
  }
};

const getItemsFromCache = async (cacheKey: string, cacheDuration: number) => {
  const now = Date.now();
  const cached = localStorage.getItem(cacheKey);

  if (cached) {
    const cachedData = JSON.parse(cached);
    if (now - cachedData.timestamp < cacheDuration) {
      return cachedData.data;
    }
    localStorage.removeItem(cacheKey);
  }
  return [];
};

const setItemsInCache = (cacheKey: string, data: []) => {
  const newCache = {
    data: data,
    timestamp: Date.now(),
  };
  localStorage.setItem(cacheKey, JSON.stringify(newCache));
};

const initializeNavigation = async () => {
  isFetchingMenu.value = true;
  const cachedItems = await getItemsFromCache(
    'cachedNavigation',
    30 * 60 * 1000,
  );
  if (cachedItems.length) {
    items.value = cachedItems;
    isFetchingMenu.value = false;
  } else {
    await fetchMerchantAccounts();
  }
};

initializeNavigation();

defineExpose({
  items,
});

watch(
  () => i18n.global.locale.value,
  () => {
    localStorage.removeItem('cachedNavigation');
    initializeNavigation();
  },
);
</script>

<style lang="scss" scoped></style>
