import { createVuetify } from 'vuetify';
import * as directives from 'vuetify/directives';
import * as components from 'vuetify/components';
import 'vuetify/styles';

const vuetify = createVuetify({
  components,
  directives,
  locale: {
    rtl: {
      ar: true, // Define RTL for Arabic
    },
  },
});
export default vuetify;
