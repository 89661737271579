<template>
  <v-theme-provider theme="dark" with-background>
    <v-toolbar height="72">
      <div class="d-flex justify-center align-center">
        <v-icon
          v-if="mdAndDown"
          icon="mdi-menu"
          class="menu-icon"
          @click="$emit('toggleDrawer')"
        ></v-icon>
        <img
          src="../assets/images/ottu-logo.png"
          alt="Ottu"
          class="ottu-logo"
        />
      </div>
      <div v-if="smAndUp" class="w-33">
        <v-text-field
          density="compact"
          placeholder="Search..."
          prepend-inner-icon="mdi-magnify"
          variant="solo"
          hide-details="auto"
        ></v-text-field>
      </div>
      <div class="d-flex justify-center align-center header-info">
        <v-theme-provider theme="light">
          <v-select
            v-model="selectedTimezone"
            :items="timezoneCountries"
            item-title="name"
            item-value="timezone"
            class="timezone-dropdown"
            aria-label="Select Timezone"
            role="listbox"
            variant="underlined"
          >
            <template v-slot:selection="{ item }">
              <img
                v-if="item.title"
                :src="require(`@/assets/images/Flags/${item.title}.png`)"
                alt="Timezone image"
                v-tooltip="
                  $t(
                    'Select the correct timezone to display records and generate reports accurately.',
                  )
                "
              />
            </template>
            <template v-slot:item="{ props, item }">
              <v-list-item
                v-if="item.title"
                v-bind="props"
                class="timezone-list"
                color="#f77d1a"
                @click="setTimezone()"
              >
                <img
                  :src="require(`@/assets/images/Flags/${item.title}.png`)"
                />
              </v-list-item>
            </template>
          </v-select>
        </v-theme-provider>
        <v-badge v-if="smAndUp" :content="2" size="small" bordered rounded="md">
          <v-icon icon="mdi-bell"></v-icon>
        </v-badge>
        <v-divider v-if="smAndUp" vertical thickness="4"></v-divider>
        <div class="user-menu">
          <img
            v-if="smAndUp"
            src="../assets/images/Frame 457.png  "
            class="user-img"
          />
          <div>{{ userDetails.username }}</div>
          <v-menu :attach="disableTeleportation">
            <template v-slot:activator="{ props }">
              <v-btn icon v-bind="props" data-test="chevron-button">
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-theme-provider theme="light" with-background>
              <v-card
                v-if="userDetails.username === undefined"
                class="action-menu-loader"
              >
                <v-skeleton-loader
                  max-width="250"
                  type="list-item-avatar@4"
                ></v-skeleton-loader>
              </v-card>
              <v-card v-else>
                <v-list min-width="250">
                  <v-list-item
                    max-width="250px"
                    v-if="userDetails.is_superuser && merchantDetails.admin_url"
                    :href="merchantDetails.admin_url"
                  >
                    <template v-slot:prepend>
                      <v-icon icon="mdi-cog-outline"></v-icon>
                    </template>
                    <v-list-item-title>{{
                      $t('Administration panel')
                    }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    max-width="250px"
                    @click="switchLanguage(route, router)"
                  >
                    <template v-slot:prepend>
                      <v-icon icon="mdi-web"></v-icon>
                    </template>
                    <v-list-item-title>{{
                      $t('Switch language')
                    }}</v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    :to="{ path: localePath('/user') }"
                    max-width="250px"
                  >
                    <template v-slot:prepend>
                      <v-icon icon="mdi-account-box"></v-icon>
                    </template>
                    <v-list-item-title>{{
                      $t('Account settings')
                    }}</v-list-item-title>
                  </v-list-item>

                  <v-list-item max-width="250px" @click="logout()">
                    <template v-slot:prepend>
                      <v-icon icon="mdi-logout"></v-icon>
                    </template>
                    <v-list-item-title>{{ $t('Logout') }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-theme-provider>
          </v-menu>
        </div>
      </div>
    </v-toolbar>
  </v-theme-provider>
</template>

<script setup lang="ts">
import { useAuthStore, useConfigStore } from '@/store';
import {
  useProcessReq,
  switchLanguage,
  localePath,
  dispatchSnackbar,
} from '@/composables/index';
import { useRouter, useRoute } from 'vue-router';
import { onMounted, ref, nextTick } from 'vue';
import NavigationService from '@/services/merchantNavigation';
import { useDisplay } from 'vuetify';

const { mdAndDown, smAndUp } = useDisplay();
const router = useRouter();
const route = useRoute();
const authStore = useAuthStore();
const configStore = useConfigStore();
const processReq = useProcessReq();

const userDetails = authStore.getUser;

defineEmits<{
  (e: 'toggleDrawer'): void;
}>();

const disableTeleportation = ref(false);
interface MerchantDetails {
  admin_url: string;
  logo_url: string;
  favicon_url: string;
  title: string;
  title_en: string;
  title_ar: string;
  subheader: string;
}

let merchantDetails: MerchantDetails = {
  admin_url: '',
  logo_url: '',
  favicon_url: '',
  title: '',
  title_en: '',
  title_ar: '',
  subheader: '',
};
const timezoneCountries = ref([
  { name: 'Kuwait', timezone: 'Asia/Kuwait' },
  { name: 'Saudi Arabia', timezone: 'Asia/Riyadh' },
  { name: 'Bahrain', timezone: 'Asia/Bahrain' },
  { name: 'Oman', timezone: 'Asia/Muscat' },
  { name: 'Qatar', timezone: 'Asia/Qatar' },
  { name: 'Egypt', timezone: 'Africa/Cairo' },
  { name: 'India', timezone: 'Asia/Kolkata' },
  { name: 'UAE', timezone: 'Asia/Dubai' },
]);
const selectedTimezone = ref('');

const logout = () => {
  authStore.getLogout?.();
};

const setMerchantConfigs = (data: MerchantDetails) => {
  const {
    admin_url,
    logo_url,
    favicon_url,
    title,
    title_en,
    title_ar,
    subheader,
  } = data;
  merchantDetails = {
    admin_url,
    logo_url,
    favicon_url,
    title,
    title_en,
    title_ar,
    subheader,
  };
};

const isUserTimeZoneNotAvailableinStorage = () => {
  let userTimeZone = localStorage.getItem('userTimeZone');
  if (userTimeZone) selectedTimezone.value = userTimeZone;
  return userTimeZone === 'null';
};

const setCountryInDropdown = (country: string) => {
  let countryTemp = country
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
  let userCountry = timezoneCountries.value.find((item) => {
    return countryTemp === item.name;
  });
  let text;
  let color;
  if (userCountry) {
    text = `Timezone set to ${userCountry.name}`;
    color = 'success';
    selectedTimezone.value = userCountry.timezone;
  } else {
    text = `Couldn't match your country. Defaulted to Kuwait. You can change it from the dropdown.`;
    color = 'error';
    selectedTimezone.value = timezoneCountries.value[0].timezone;
  }
  dispatchSnackbar(text, color);
  localStorage.setItem('userTimeZone', selectedTimezone.value);
};

const setTimezone = async () => {
  if (selectedTimezone.value) {
    try {
      await processReq(
        NavigationService.setTimeZone({ timezone: selectedTimezone.value }),
        false,
        true,
      );
      localStorage.setItem('userTimeZone', selectedTimezone.value);
    } catch (err) {
      console.error('Failed to set timeZone:', err);
    }
  }
};

onMounted(async () => {
  const configData = configStore.getConfig as MerchantDetails;
  setMerchantConfigs(configData);
  await nextTick();
  if (isUserTimeZoneNotAvailableinStorage()) {
    setCountryInDropdown('');
  }
});
defineExpose({
  disableTeleportation,
});
</script>

<style lang="scss" scoped>
.v-toolbar {
  background: black;
  :deep(.v-toolbar__content) {
    justify-content: space-between;
  }
}
.timezone-list {
  padding: 12px;
  border-bottom: 1px solid;
  :deep(.v-list-item__content) {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: row-reverse;
  }
  :deep(img) {
    width: 45px;
    margin-right: 10px;
  }
}
.timezone-dropdown {
  max-width: 70px;
  margin: 1px 30px 0 20px !important;
  @media screen and (max-width: 420px) {
    margin: 1px !important;
  }
  :deep(.v-select__selection img) {
    width: 45px;
    margin-inline-end: 0px;
  }
  :deep(.v-field__outline::before) {
    display: none;
  }
  :deep(.v-select__menu-icon) {
    margin-top: 10px;
    color: white;
  }
  :deep(.v-list-item-title img) {
    width: 45px;
  }
  :deep(.v-field) {
    color: transparent;
  }
}

.ottu-logo {
  padding-left: 3rem;
  margin-right: 10px;
  height: 40px;
  @media screen and (max-width: 1280px) {
    padding-left: 1rem;
  }
  @media screen and (max-width: 420px) {
    height: 30px;
    padding-left: 0.5rem;
    margin-right: 8px;
  }
}
.menu-icon {
  padding-left: 10px;
}
.v-input {
  :deep(.v-field__input) {
    min-height: auto;
  }
}
.header-info {
  gap: 1rem;
  padding-right: 3rem;
  @media screen and (max-width: 991px) {
    padding-left: 0;
    padding-right: 0;
    gap: 2px;
  }
  :deep(.v-badge__badge) {
    padding: 0;
  }
  .user-menu {
    display: flex;
    align-items: center;
    gap: 8px;
    @media screen and (max-width: 420px) {
      gap: 0px;
    }
  }
  .user-img {
    height: 40px;
    width: 40px;
    border-radius: 6px;
  }
}
</style>
