import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
// import HomeView from '../views/HomeView.vue';
// const IS_ENABLED_KEYCLOAK = localStorage.getItem('isKeycloakAuth') === 'true';
// const IS_LOGGED_IN = localStorage.getItem('VUE_APP_TOKEN');
// import { i18n } from './../plugins/i18n';

const routes: Array<RouteRecordRaw> = [
  // {
  //   path: '/:lang/',
  //   exact: true,
  //   redirect:
  //     IS_ENABLED_KEYCLOAK || IS_LOGGED_IN
  //       ? `/${i18n.global.locale}/dashboard`
  //       : `/${i18n.global.locale}/login`,
  // },
  // {
  //   name: 'login',
  //   path: '/:lang/login',
  //   exact: true,
  //   ...(IS_ENABLED_KEYCLOAK && {
  //     redirect: `/${i18n.global.locale}/dashboard`,
  //   }),
  // },
  // {
  //   name: 'forget-password',
  //   path: '/:lang/forget-password',
  //   exact: true,
  //   //redirect: `/${i18n.global.locale}/dashboard`
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
