import { i18n } from '@/plugins/i18n';
import { RouteLocationNormalizedLoadedGeneric, Router } from 'vue-router';
import { getCurrentInstance } from 'vue';

import { useRoute } from 'vue-router';
import { NavigationItems } from '../interface/navigation';

export const switchLanguage = (
  route: RouteLocationNormalizedLoadedGeneric,
  router: Router,
) => {
  const lang = i18n.global.locale.value === 'ar' ? 'ar' : 'en';
  const target_lang = i18n.global.locale.value === 'en' ? 'ar' : 'en';
  i18n.global.locale.value = target_lang;
  const path = route?.fullPath?.replaceAll(`/${lang}/`, `/${target_lang}/`);
  router.push({ path: path });
};
export const localePath = (path: string) => {
  return `/${i18n.global.locale.value}${path}`;
};

export const dispatchSnackbar = (text = '', color = 'info') => {
  const event = new CustomEvent('snackbar', {
    detail: { text, color },
  });
  window.dispatchEvent(event);
};

export const useProcessReq = () => {
  const processReq =
    getCurrentInstance()?.appContext?.config?.globalProperties?.$processReq;
  return processReq;
};

export const isActive = (item: NavigationItems): boolean => {
  const route = useRoute();
  if (route.path === '/') return false;
  const regex = /\/(en|ar)(\/.*)/;
  const match = route.path.match(regex);
  const normalizedRoute = match ? match[2] : '';

  if (
    item?.children?.some((element) => element.route_url === normalizedRoute)
  ) {
    return true;
  }

  if (item.route_url === normalizedRoute) {
    return true;
  }

  return false;
};
