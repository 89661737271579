import { useConfigStore, useAuthStore } from '@/store';

export default class SessionStateWorker {
  worker: SharedWorker;

  constructor() {
    this.worker = new SharedWorker('/scripts/sessionStateWorker.js', {
      type: 'module',
    });
    this.worker.port.start();

    this.worker.port.addEventListener('message', async (event) => {
      const configStore = useConfigStore();
      const authStore = useAuthStore();

      switch (event.data.action) {
        case 'updateCountdown':
          configStore.setIdleCountdownTimer(event.data.countdown);
          break;

        case 'stopIdleCountdown':
          configStore.setIdleCountdownTimer(60);
          break;

        case 'logout':
          authStore.getLogout?.();
          break;

        case 'refresh':
          try {
            authStore.getRefreshToken?.(
              !configStore.getIsKeycloakAuth
                ? localStorage.getItem('refresh')
                : null,
            );
          } catch {
            this.logoutAcrossTabs();
          }
          break;

        default:
          break;
      }
    });
  }

  sendMessage(message: object) {
    this.worker?.port.postMessage(message);
  }

  logoutAcrossTabs() {
    this.worker?.port.postMessage({ action: 'logout' });
    useAuthStore().getLogout?.();
  }
}
