import mitt from 'mitt';
import type { App } from 'vue';
const emitter = mitt();

const Mitt = {
  install(app: App) {
    app.config.globalProperties.$emitter = emitter;
  },
};

export default Mitt;
